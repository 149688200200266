<template>
  <v-card class="mt-0" style="padding:20px;">
    <h2 style="margin-bottom:10px;">历史考试列表</h2> 
    <v-data-table 
      item-key="id" 
      :headers="headers"
      :items="dataList"
      :options.sync="options"
      hide-default-footer
      class="elevation-1 worktab"
    > 
      <template v-slot:item.lookexaminfo = "{ item }">
        <template>
          <v-btn text color="#437EE2" style="padding:0 !important;font-size:1rem;" @click="doLmind(item)">
            <v-icon :left="true">
              mdi-paperclip
            </v-icon>
            查看详情
          </v-btn>
        </template> 
      </template> 
    </v-data-table>
    <v-pagination
      style="padding:20px 0 0;"
      v-model="options.current"
      :length="options.pages"
    ></v-pagination> 
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex";
import { getList, postAction, deleteAction, putAction } from '@/api/homework'
export default {
  name: 'Kshistory',
  data: () => {
    return {
      loading: false,
      headers: [
        { text: '考试名称', value: 'ksname', sortable: false },
        { text: '考试完成时间', value: 'kscomplateTime', width: 140, align: 'center', sortable: false },
        { text: '考试得分', value: 'kscore', width: 100, align: 'center', sortable: false },
        { text: '试卷解析', value: 'lookexaminfo',  width: 100, align: 'center',sortable: false }
      ],
      dataList:[],
      total: 0,
      baseURL:'',
      options: {
        current: 1, // 当前页码，默认第一页
        size: 10, // 每页显示数量
        pages: 1
      }, 
    }
  },
  watch: {
    'options.current': {
      handler(val, oldVal) {
        this.init()
      }
    }
  },
  created() {
    this.baseURL = window._CONFIG['staticDomainURL'] + '/'
    this.init() 
  }, 
  methods: {
    moment,
    ...mapGetters(["userInfo", 'token']),
    init() {
      this.options.current = 1
      this.total = 1
      this.options.size = 1
      this.options.pages = 1
      this.dataList = [{ksname:'css','kscomplateTime':'2020-09-01','kscore':'20','lookexaminfo':'查看详情'},{ksname:'css','kscomplateTime':'2020-09-01','kscore':'20','lookexaminfo':'查看详情'}] 
      // const param = {"function":{"currentUser()":"currentUser()","createTime()":"createTime()","orgCode()":"orgCode()","getJobNumber()":"getJobNumber()"},"[]":{"query":2,"count":"100","page":"0","Ys_job_person":{"user_id{}@":{"from":"Sys_user_simple_view","Sys_user_simple_view":{"@column":"id","username@":"function/currentUser"}},"ys_job_id{}@":{"from":"Ys_job","Ys_job":{"@column":"id","job_status":"1"}}},"Ys_job":{"id@":"[]/Ys_job_person/ys_job_id","@order":"publish_time-"},"Sys_user_simple_view":{"@column":"id,username,realname","id@":"[]/Ys_job_person/user_id"},"Ys_job_upload_view[]":{"Ys_job_upload_view":{"ys_job_person_id@":"[]/Ys_job_person/id","create_by@":"function/currentUser","@order":"create_time-"}},"Ys_job_person_look_view[]":{"Ys_job_person_look_view":{"ys_job_person_id@":"[]/Ys_job_person/id","@order":"create_time-","@group":"realname","@column":"realname"}},"Ys_mind":{"business_id@":"/Ys_job/id","create_by@":"function/currentUser","table_name":"Ys_job","field_name":"id","category":"mywork","is_new":"1"},"Ys_mind_data[]":{"Ys_mind_data":{"mind_id@":"[]/Ys_mind/id"}}},"total@":"/[]/total","info@":"/[]/info"}
      // param['[]'].count = this.options.size
      // param['[]'].page = this.options.current - 1
      // if (this.search.title && this.search.title.length > 0) this.$set(param['[]'].Ys_job_person['ys_job_id{}@'].Ys_job, 'job_name$', '%' + this.search.title + '%')
      // if (this.search.date && this.search.date.length === 10) this.$set(param['[]'].Ys_job_person['ys_job_id{}@'].Ys_job, 'publish_time%', this.search.date + ' 00:00:00,' + this.search.date + ' 23:59:59')
      // getList(param).then((response) => {
      //   if (response.code === 200) {
          // this.options.current = response.info.page + 1
          // this.total = response.info.total
          // this.options.size = response.info.count
          // this.options.pages = response.info.max + 1
          // this.articleList = response['[]'] || [] 
      //   } else {
      //     this.$message.error('获取历史考试列表出错：' + response.msg)
      //   }
      // })
    },
    // 获取当前用户部门名称
    getCurrentDepartName(){
      let currDepartName = ""
      let userInfo = this.userInfo()
      let Departs = localStorage.getItem('pro__Login_Departs') ? JSON.parse(localStorage.getItem('pro__Login_Departs')).value : []
      if(userInfo.orgCode && Departs && Departs.length>0){
        for(let i of Departs){
          if(i.orgCode == userInfo.orgCode){
            currDepartName = i.departName
            break
          }
        }
      }
      return currDepartName
    },
    doLmind(row){ 
      console.log(row)
      let token = this.token()
      let realname = this.userInfo().realname 
      let dept = this.getCurrentDepartName() 
      if(row && row.link_address) {
        let newUrl = row.link_address+"?cnname="+realname+"&deptname="+dept+"&token="+token
        window.open(newUrl, '考试查看')
      } 
    },
    loadData(){
      this.init()
    }
  }
}
</script>
<style scoped>
.worktab /deep/ td,.worktab /deep/ th{padding:0 5px !important;}
.worktab /deep/ th{font-size:1rem !important;color:#000 !important;background:#ccc;}
.workpre /deep/ .v-card__text{padding:5px;}
</style>
